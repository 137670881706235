<template>
  <div>

    <HeaderMain />

    <div style="padding-top: 100px;">
      <div v-if="loading">
        Loading PDF...
      </div>
      <div v-else v-for="pageNum in totalPages" :key="pageNum">
        <pdf
            :src="pdfSrc"
            :page="pageNum"
            @error="error"
        ></pdf>
      </div>
    </div>

    <div class="section color" v-if="isPrivacyPolicy">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container last form w-container">
        <div class="from-login w-form">
          <form id="wf-form-Login" name="wf-form-Login" data-name="Login" method="get" class="form-input-login" data-wf-page-id="66628b11ce9ac8f5a081c327" data-wf-element-id="80cb5f26-991f-0842-cdc6-d86456db53e0">
            <v-form ref="form_drop_personal_data" v-model="valid_drop_personal_data" lazy-validation>
              <h1 class="heading-form">Удалить аккаунт</h1>
              <h3 class="heading-form is-3">Если вы хотите навсегда удалить свою учётную запись, введите свои данные</h3>
              <div class="input-box" style="margin-top: 20px;">
                <label class="input-label">Телефон</label>
                <v-text-field
                    inputmode="numeric"
                    outlined
                    type="text"
                    :rules="phoneRules"
                    v-mask="'+7##########'"
                    v-model="form_data.phone"
                ></v-text-field>
              </div>
              <div class="input-box">
                <label for="Email" class="input-label">Email</label>
                <v-text-field
                    id="Email"
                    name="Email"
                    outlined
                    type="text"
                    :rules="emailRules"
                    v-model="form_data.email"
                ></v-text-field>
              </div>

              <a
                  id="openLabel"
                  @click="dropPersonalData"
                  style="margin-top: 15px; width: 100%;"
                  aria-current="page"
                  class="button-bust w-button w--current">
                Удалить
              </a>
              <link rel="prerender" @click="dropPersonalData">
            </v-form>
          </form>
        </div>
      </div>
    </div>

    <Footer />

  </div>
</template>

<script>
import { Footer, HeaderMain } from '/src/app/shared/components';
import loadjs from 'loadjs';
import pdf from 'vue-pdf';
import pdfPageCounter from 'pdf-page-counter';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default {
  name: 'Documents',
  data() {
    return {
      valid_drop_personal_data: false,
      totalPages: 0,
      pdfSrc: '',
      loading: true,

      form_data: {
        phone: '',
        email: ''
      },
      // pdfSrc: '/documents_policy/privacy_policy.pdf'

      phoneRules: [
        v => !!v || 'Поле не может быть пустым',
        v => /^(8\d{10}|\+7\d{10})$/.test(v) || 'Телефон должен состоять из 11 цифр и начинаться с +7'
      ],
      emailRules: [
        v => !!v || 'Поле не может быть пустым',
        v => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || 'Неправильный формат email'
      ],
    };
  },
  components: {
    Footer,
    HeaderMain,
    pdf
  },
  created() {
    this.loadPdf();
  },
  mounted() {
    this.loadScripts();
  },

  activated() {
    this.loadScripts();
  },
  watch: {
    documentType: 'loadPdf'
  },
  computed: {
    documentType() {
      return this.$route.params.documentType;
    },
    isPrivacyPolicy() {
      return this.documentType === 'privacy-policy';
    }
  },
  methods: {
    dropPersonalData() {
      if (this.$refs.form_drop_personal_data.validate()) {
        this.$store.dispatch('DROP_PERSONAL_DATA', this.form_data).then(() => {
          this.$buefy.toast.open({
            message: 'Персональные данные удалены',
            type: 'is-success'
          });

          this.$router.push('/');
        }).catch((message) => {
          this.$buefy.toast.open({
            message: message,
            type: 'is-danger'
          })
        });
      }
    },
    async loadPdf() {
      this.loading = true;
      this.pdfSrc = `/documents_policy/${this.documentType}.pdf`;

      try {
        const pdfBuffer = await this.fetchPdf(this.pdfSrc);
        const pdfData = await pdfPageCounter(pdfBuffer);
        this.totalPages = pdfData.numpages;
        this.loading = false;
      } catch (error) {
        this.error(error);
      }
    },
    async fetchPdf(url) {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.arrayBuffer();
    },
    error(error) {
      console.error('Error:', error);
      this.loading = false;
    },

    loadScripts() {
      // Удаляем старые скрипты
      this.removeOldScripts();

      // Подключаем jQuery
      loadjs(
          "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=652921c910bae02d8870dddf",
          {
            success: () => {
              console.log('jQuery loaded');

              // Подключаем Webflow
              loadjs('./js/webflow.js', {
                success: () => {
                  console.log('Webflow loaded');
                },
                error: () => {
                  console.error('Failed to load Webflow');
                }
              });
            },
            error: () => {
              console.error('Failed to load jQuery');
            }
          }
      );
    },

    removeOldScripts() {
      const scripts = document.querySelectorAll('script[src*="jquery-3.5.1.min.dc5e7f18c8.js"], script[src*="webflow.js"]');
      scripts.forEach(script => {
        script.parentNode.removeChild(script);
      });
    },
  },
  beforeRouteUpdate (to, from, next) {
    next(vm => {
      window.scrollTo(0, 0);
    });
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      window.scrollTo(0, 0);
    });
  },
};
</script>

<style scoped>
.heading-form.is-3 {
  font-size: 16px;
  line-height: normal;
  margin-top: 7px;
}
</style>
